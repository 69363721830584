import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl';
import { Link } from 'gatsby';

const PageNotFound = () => {
  const intl = useIntl();

  return (
    <Layout>
      <SEO
        title={intl.formatMessage({ id: 'notFound.title' })}
        lang={'es'}
        description={intl.formatMessage({ id: 'notFound.subtitle' })}
      />

      <section className="height-100 text-center">
        <div className="container pos-vertical-center">
          <div className="row">
            <div className="col-md-12">
              <h1 className="h1--large">404</h1>

              <p className="lead">
                <FormattedMessage id="notFound.subtitle" />
              </p>

              <Link to={'/'}>
                <FormattedMessage id="notFound.goBack" />
              </Link>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default PageNotFound;
